'use client'

import { Disclosure } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'

const faqs = [
  {
    question: '타입잇으로 뭘 만들 수 있나요?',
    answer:
      '타입잇으로 다양한 문구들을 만들 수 있습니다. 예를 들어, 네이버 블로그, 쿠팡 등의 이커머스 상품 설명, Facebook & Instagram 캠페인 문구, 블로그 포스팅 등등 을 쉽고 빠르게 만들 수 있습니다.',
  },
  {
    question: '타입잇 크레딧은 평생 유효한가요?',
    answer: '구입일로 부터 1년 동안 사용 가능합니다.',
  },
  {
    question: '구매에 대한 환불 접수는 어떻게 하나요?',
    answer: 'help@typeit.ai로 30일 이내에 문의 주시면 바로 환불처리 해드립니다.',
  },
  {
    question: '구매했는데 크레딧이 충전되지 않았어요. 어떻게 하나요?',
    answer: 'help@typeit.ai로 문의 주시면 바로 처리해드립니다.',
  },
  {
    question: '타입잇은 어떻게 만들어졌나요?',
    answer: 'Open AI & Anthropic의 LLM을 기반으로 만들어졌습니다. ',
  },
  {
    question: '타입잇 API가 있나요?',
    answer: '아쉽게도 타입잇은 API를 제공하고 있지 않습니다.',
  },
  {
    question: '이메일 & 패스워드로 로그인 할 수 있나요?',
    answer: '타입잇은 고객 분들의 보안을 위해서 Google, 네이버, 카카오 로그인만 지원합니다.',
  },
]

export function Faqs() {
  return (
    <div className=''>
      <div className='mx-auto max-w-7xl px-6 py-24 lg:px-8 lg:py-40 sm:py-32'>
        <div className='mx-auto max-w-4xl divide-y divide-gray-900/10'>
          <div>
            <h2 className='font-bold text-2xl text-gray-900 leading-10 tracking-tight'>자주 묻는 질문</h2>
            <p className='mt-2 text-gray-700'>
              여전히 궁금한 점이 있으시다면 <span className='text-blue-500'>help@typeit.ai</span> 로 문의 주세요.
            </p>
          </div>
          <dl className='mt-10 space-y-6 divide-y divide-gray-900/10'>
            {faqs.map((faq, index) => (
              <dt key={index}>
                <Disclosure as='div' className='pt-6'>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='flex w-full items-start justify-between text-left text-gray-900'>
                        <span className='font-semibold text-base leading-7'>{faq.question}</span>
                        <span className='ml-6 flex h-7 items-center'>
                          {open ? (
                            <MinusIcon className='h-6 w-6' aria-hidden='true' />
                          ) : (
                            <PlusIcon className='h-6 w-6' aria-hidden='true' />
                          )}
                        </span>
                      </Disclosure.Button>
                      <Disclosure.Panel as='dd' className='mt-2 pr-12'>
                        <p className='text-base text-gray-600 leading-7'>{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </dt>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
